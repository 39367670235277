import { render, staticRenderFns } from "./WalletManagementPortal.vue?vue&type=template&id=bfe46a34&scoped=true"
import script from "./WalletManagementPortal.vue?vue&type=script&lang=js"
export * from "./WalletManagementPortal.vue?vue&type=script&lang=js"
import style0 from "./WalletManagementPortal.vue?vue&type=style&index=0&id=bfe46a34&prod&scoped=true&lang=css"
import style1 from "./WalletManagementPortal.vue?vue&type=style&index=1&id=bfe46a34&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe46a34",
  null
  
)

export default component.exports